import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import { QuestionResponse } from "../../../interface";
import { TitleSkeleton, ParagraphSkeleton } from "../../../components/Skeleton";
import classnames from "classnames";
import Citations from "../../../components/Citations/Citations";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";

import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import ColourSelect from "../../../components/ColourSelect/ColourSelect";
import SharedTemplate from "../../../layouts/SharedTemplate";

interface LongInformProps {
  templateOrderIndex: number;
  loading: boolean;
  response: QuestionResponse;
  testingTemplates: boolean;
  message: {
    templateYScroll: number;
  };
}

export const LongInform = forwardRef(
  ({
    templateOrderIndex,
    response,
    loading,
    testingTemplates,
    message,
  }: LongInformProps) => {
    const [version, setVersion] = useState("white");
    const { content, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, [templateOrderIndex]);

    const [templateColour, setTemplateColour] = useState("white");
    useEffect(() => {
      if (testingTemplates) {
        if (templateColour === "white") {
          setVersion("white");
        }
        if (templateColour === "dark") {
          setVersion("dark");
        }
        if (templateColour === "grey") {
          setVersion("grey");
        }
      }
    }, [templateColour, testingTemplates]);

    const templateRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    return (
      <SharedTemplate
        id="long-inform"
        templateRef={templateRef}
        textColor={version}
      >
        <div
          data-testid="long-inform-container"
          id="long-inform"
          className="relative rounded-t-[1.5rem] z-40"
        >
          <div className="overflow-hidden rounded-t-[1.5rem]">
            <Grid customClasses="gap-x-4 desktop:!mx-0 relative rounded-t-[1.5rem]">
              <div
                id="long-inform-header-content"
                className="mobile:col-start-1 desktop:col-start-2 col-span-full mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0"
              >
                <h2
                  id="long-inform-tagline"
                  className="mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:text-[1rem] desktop:text-lg font-medium uppercase"
                >
                  {query}
                </h2>
                {testingTemplates && (
                  <ColourSelect
                    templateColour={templateColour}
                    setTemplateColour={setTemplateColour}
                  ></ColourSelect>
                )}
                {!content.header ? (
                  <TitleSkeleton rows={1} />
                ) : (
                  <h3
                    id="long-inform-header"
                    className={classnames(
                      "mobile:pt-[36px] desktop:pt-[36px] mobile:text-4xl desktop:text-[40px] font-bold",
                      {
                        "!text-text-greyHeader":
                          version === "white" || version === "grey",
                        "!text-white": version === "dark",
                      }
                    )}
                  >
                    {content.header}
                  </h3>
                )}
              </div>

              <div
                id="long-inform-image-content"
                className="mobile:col-start-1 desktop:col-start-2 desktop:col-span-6 mobile:col-span-full mobile:pt-[2.25rem] desktop:pt-[1.375rem] desktop:flex desktop:flex-col mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0"
              >
                {!content.tagline ? (
                  <ParagraphSkeleton lines={6} />
                ) : (
                  <p
                    id="long-inform-paragraph-1"
                    className={classnames(
                      "mobile:text-xl desktop:text-2xl font-light",
                      {
                        "!text-text-greyHeader":
                          version === "white" || version === "grey",
                        "!text-white": version === "dark",
                      }
                    )}
                  >
                    {content.tagline}
                  </p>
                )}
              </div>
              <div
                id="long-inform-answer-content"
                className="mobile:col-start-1 desktop:col-start-9 mobile:col-span-full desktop:col-end-24 mobile:pt-[36px] desktop:pt-[1.375rem] mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0 desktop:pb-[2.5rem]"
              >
                {!content.paragraphs[0] ? (
                  <ParagraphSkeleton
                    lines="default"
                    classNames="mb-[1.375rem]"
                  />
                ) : (
                  <TypewriterText
                    id="long-inform-paragraph-2"
                    className="mobile:text-sm desktop:text-lg font-light pb-[1.375rem]"
                    hasAllText={content.paragraphs[0].done}
                    text={
                      content.paragraphs[0].text[
                        content.paragraphs[0].text.length - 1
                      ] === "."
                        ? content.paragraphs[0].text
                        : `${content.paragraphs[0].text}.`
                    }
                    isDone={para1Done}
                    setIsDone={setPara1Done}
                  />
                )}

                {!content.paragraphs[1] ? (
                  <ParagraphSkeleton lines="tablet" />
                ) : (
                  para1Done && (
                    <TypewriterText
                      id="long-inform-paragraph-3"
                      className="mobile:text-sm desktop:text-lg font-light"
                      hasAllText={content.paragraphs[1].done}
                      text={content.paragraphs[1].text}
                      isDone={para2Done}
                      setIsDone={setPara2Done}
                    />
                  )
                )}
              </div>
            </Grid>
            {para2Done && (
              <Citations
                metadata={response.metadata}
                version={version}
                usedSnippetIds={response.used_snippet_ids}
              />
            )}
            {para2Done && (
              <MediaLinksV2
                assets={assets}
                display="fullwidth"
                version={version}
              />
            )}
          </div>
        </div>
      </SharedTemplate>
    );
  }
);
