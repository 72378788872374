import React from "react";
import { Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../components";
import { QuestionResponse } from "../../interface";
import { TitleSkeleton } from "../../components/Skeleton";
import classnames from "classnames";
import { ButtonScrollDown } from "../../components/Button/ButtonScrollDown";
import TypewriterText from "../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import SharedTemplate from "../../layouts/SharedTemplate";

interface PresentProps {
  templateOrderIndex: number;
  loading: boolean;
  response: QuestionResponse;
  message: {
    templateYScroll: number;
  };
}

export const Preset = forwardRef(
  (
    { templateOrderIndex, response, loading, message }: PresentProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, query } = response;
    const [para1Done, setPara1Done] = useState(false);
    const templateRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, [templateOrderIndex]);

    return (
      <SharedTemplate templateRef={templateRef} id="present">
        <Grid
          testId={`grid-${templateOrderIndex}`} // Unique test ID based on templateOrderIndex
          customClasses={classnames(
            "gap-x-4 relative mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] rounded-t-[1.5rem]",
            {
              "bg-white": version === "white",
              "bg-grey-200": version === "grey",
              "bg-grey-1000 text-white": version === "dark",
            }
          )}
        >
          <div
            id="single-answer-container"
            className="h-full mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-24 flex flex-col row-start-1 mobile:pb-[4.5rem] desktop:pb-[8.375rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <div>
              <h2
                id="tagline"
                className="mobile:text-base desktop:text-xl font-semibold uppercase"
              >
                {query}
              </h2>
              <div className="mobile:pt-[1.5rem] desktop:pt-[1.875rem]">
                {!content.paragraphs[0] ? (
                  <TitleSkeleton rows={1} />
                ) : (
                  <TypewriterText
                    id="single-answer"
                    className="!font-default text-2xl"
                    hasAllText={content.paragraphs[0].done}
                    text={content.paragraphs[0].text}
                    isDone={para1Done}
                    setIsDone={setPara1Done}
                  />
                )}
              </div>
              <div
                className={classNames("mobile:hidden desktop:block pt-16", {
                  "desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible":
                    para1Done,
                  "desktop:translate-y-full desktop:invisible desktop:h-0":
                    !para1Done,
                })}
              >
                {version === "white" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="white"
                  />
                )}
                {version === "dark" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="dark"
                  />
                )}
                {version === "grey" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="grey"
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>
      </SharedTemplate>
    );
  }
);
