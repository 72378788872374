import React, { useEffect, useState, useRef } from "react";
import { TypewriterText } from "../../components";
import classnames from "classnames";
import { useConfig } from "../../context/config-context";
import ClientIcon from "../../components/ClientIcon";

interface InitialNewProps {
  open: boolean;
  setOpen: () => void;
}

export default function InitialNew({ open, setOpen }: InitialNewProps) {
  const { config } = useConfig();
  const [hover, setHover] = useState(false);
  const [textIsDone, setTextIsDone] = useState(false);
  const pillRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (new URL(event.origin).href !== new URL(config.HOST).href) {
        return;
      }

      if (!open) {
        setHover(false);
      }

      if (event.data === "OPEN") {
        setOpen();
      }

      if (event.data === "MOUSEOVER") {
        setHover(true);
      }

      if (event.data === "MOUSEOUT") {
        setHover(false);
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [config.HOST, setOpen]);

  return (
    <button
      className={classnames(
        "justify-left flex items-end overflow-hidden absolute left-4 bottom-4"
      )}
    >
      <div
        ref={pillRef}
        className={classnames(
          "flex content-center items-center z-50 cursor-pointer text-white bg-black/80 pl-[1.75rem] pr-4 py-4 backdrop-blur-[20px] h-[68px] w-[114px] rounded-t-[50px] rounded-br-[50px] text-left transition-all duration-300 ease-in-out",
          {
            "hidden mb-[30px] ml-[30px]": open,
            "w-[380px]": hover,
          }
        )}
      >
        <div className={classnames("flex mr-[0.65rem] justify-left")}>
          <ClientIcon />
        </div>
        <TypewriterText
          id="initial"
          isDone={textIsDone}
          setIsDone={setTextIsDone}
          className={classnames("text-base whitespace-nowrap overflow-hidden")}
          hasAllText
          text={"Hello! How can I help you today?"}
        />
      </div>
    </button>
  );
}
