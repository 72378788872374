import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { Config } from "../interface";
import allConfig from "../config";

export interface ConfigContextProps {
  config: Config;
}

const ConfigContext = createContext<ConfigContextProps>({
  config: {} as Config,
});

const ConfigProvider = (props: React.PropsWithChildren) => {
  const [config, setConfig] = useState<null | Config>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const client = urlParams.get("client") || "wpp";
    const _config = allConfig[client] || allConfig["wpp"];
    setConfig(_config);
  }, []);

  if (!config) {
    return null;
  }

  return (
    <ConfigContext.Provider
      value={{
        config,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

const useConfig = () => useContext(ConfigContext);

export { ConfigProvider, useConfig };
