import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import { QuestionResponse } from "../../../interface";
import {
  ParagraphSkeleton,
  ImageSkeleton,
  TitleSkeleton,
} from "../../../components/Skeleton";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";

import Citations from "../../../components/Citations/Citations";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import ColourSelect from "../../../components/ColourSelect/ColourSelect";
import SharedTemplate from "../../../layouts/SharedTemplate";

interface ShortInformProps {
  templateOrderIndex: number;
  loading: boolean;
  response: QuestionResponse;
  testingTemplates: boolean;
  message: {
    templateYScroll: number;
  };
}

export const ShortInform = forwardRef(
  ({
    templateOrderIndex,
    response,
    loading,
    testingTemplates,
    message,
  }: ShortInformProps) => {
    const [version, setVersion] = useState("white");
    const { content, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const templateRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    useEffect(() => {
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, [templateOrderIndex]);

    const [templateColour, setTemplateColour] = useState("white");
    useEffect(() => {
      if (testingTemplates) {
        if (templateColour === "white") {
          setVersion("white");
        }
        if (templateColour === "dark") {
          setVersion("dark");
        }
        if (templateColour === "grey") {
          setVersion("grey");
        }
      }
    }, [templateColour, testingTemplates]);

    return (
      <SharedTemplate
        id="short-inform"
        textColor={version}
        templateRef={templateRef}
        className="rounded-t-[1.5rem]"
      >
        <div className="overflow-hidden rounded-t-[1.5rem]">
          <Grid
            testId="short-inform-theme2"
            customClasses="gap-0 h-full relative desktop:!mx-0 rounded-t-[3.125rem]"
          >
            <div
              id="short-inform-tagline-container"
              className="mobile:pt-[2.625rem] tablet:pt-[2.625rem] mobile:pb-[2.25rem] tablet:pb-[1.5rem] desktop:pt-[5.25rem] desktop:pb-[2.25rem] desktop:col-start-2 col-span-full mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
            >
              <h2
                id="short-inform-tagline"
                className="mobile:text-[1rem] desktop:text-lg font-medium uppercase"
              >
                {query}
              </h2>
              {testingTemplates && (
                <ColourSelect
                  templateColour={templateColour}
                  setTemplateColour={setTemplateColour}
                ></ColourSelect>
              )}
              {!content.header ? (
                <TitleSkeleton rows={1} />
              ) : (
                <h3
                  id="short-inform-header"
                  className="mobile:pt-[36px] desktop:pt-[36px] mobile:text-4xl desktop:text-[40px] font-bold"
                >
                  {content.header}
                </h3>
              )}
            </div>
            <div
              id="short-inform-header-content"
              className="col-start-1 desktop:col-span-9 mobile:col-span-full desktop:min-h-[260px] h-full"
            >
              <div className="flex flex-col h-full w-full desktop:pr-[2] mobile:py-[1.5rem] desktop:py-[44px] grid grid-cols-9">
                <div className=" grid-col-span-4 col-start-2 col-span-full">
                  {!content.paragraphs[0] ? (
                    <ParagraphSkeleton />
                  ) : (
                    <TypewriterText
                      id="short-inform-paragraph-1"
                      className="text-[1.125rem] font-light pr-[28px]"
                      hasAllText={content.paragraphs[0].done}
                      text={content.paragraphs[0].text}
                      isDone={para1Done}
                      setIsDone={setPara1Done}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              id="short-inform-image-content"
              className="mobile:col-start-1 desktop:col-start-10 w-full col-span-full desktop:min-h-[260px] h-full"
            >
              <div className="mobile:col-start-1 w-full desktop:col-start-1 col-span-full desktop:col-end-13 desktop:row-start-1 h-full desktop:pb-0">
                {!content.imgURL ? (
                  <ImageSkeleton display="short-inform" />
                ) : (
                  <>
                    {!content.imgURL ? (
                      <div className="w-full desktop:min-h-[260px] h-full max-h-full bg-gradient-to-r from-cyan-500 to-blue-500"></div>
                    ) : (
                      <div className="relative w-full h-full">
                        <div
                          style={{
                            backgroundImage: `url('${content.imgURL}')`,
                          }}
                          className={`w-full mobile:min-h-[175px] tablet:min-h-[260px] h-full max-h-full bg-cover bg-center bg-no-repeat`}
                        ></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Grid>
          <hr className="h-[28px] border-none" />
          <div data-testid="media-links-v2" className="hidden" />
          {para1Done && (
            <>
              <Citations
                metadata={response.metadata}
                version={version}
                usedSnippetIds={response.used_snippet_ids}
              />
              <div className="mt-[40px]">
                <MediaLinksV2
                  assets={assets}
                  display="fullwidth"
                  version={version}
                />
              </div>
            </>
          )}
        </div>
      </SharedTemplate>
    );
  }
);
