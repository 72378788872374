import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Grid, TypewriterText } from "../";
import classNames from "classnames";

interface FailProps {
  failText: string;
  templateOrderIndex: number;
  loading: boolean;
  message: {
    templateYScroll: number;
  };
}

export const Fail = forwardRef(
  ({ failText, templateOrderIndex, loading, message }: FailProps) => {
    const [failTextDone, setFailTextDone] = useState(false);
    const templateRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    return (
      <div ref={templateRef} id="fail" className="relative">
        <Grid
          customClasses={classNames(
            "bg-white desktop:!mx-0 gap-x-4 h-full relative pt-[30px] rounded-t-[1.5rem]",
            {
              "-mt-[2.5rem]": templateOrderIndex !== 0,
            }
          )}
        >
          <div
            data-testid="fail"
            className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-24 desktop:pt-[84px] pt-[42px] desktop:pb-[30px] pb-[36px] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <h2
              id="long-hero-tagline"
              className="mobile:text-base desktop:text-xl font-extrabold uppercase"
            >
              This query has failed
            </h2>
          </div>
          <div
            id="long-hero-subheading-content"
            className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-24 desktop:pb-[134px] pb-[72px] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <p id="fail-message">
              <TypewriterText
                id="long-hero-paragraph-2"
                className="text-2xl font-light"
                text={failText}
                hasAllText
                isDone={failTextDone}
                setIsDone={setFailTextDone}
              />
            </p>
          </div>
        </Grid>
      </div>
    );
  }
);
