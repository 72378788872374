import { forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
  OneLineQuestionSkeleton,
} from "../../../components/Skeleton";
import Citations from "../../../components/Citations/Citations";
import classnames from "classnames";
import { QuestionResponse } from "../../../interface";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import ColourSelect from "../../../components/ColourSelect/ColourSelect";
import SharedTemplate from "../../../layouts/SharedTemplate";

interface XThingsInspireProps {
  templateOrderIndex: number;
  loading: boolean;
  response: QuestionResponse;
  testingTemplates: boolean;
  message: {
    templateYScroll: number;
  };
}

export const XThingsInspire = forwardRef(
  ({
    templateOrderIndex,
    response,
    message,
    loading,
    testingTemplates,
  }: XThingsInspireProps) => {
    const [version, setVersion] = useState("white");
    const { content, query, assets } = response;

    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [para3Done, setPara3Done] = useState(false);
    const [header1Done, setHeader1Done] = useState(false);
    const [header2Done, setHeader2Done] = useState(false);
    const [header3Done, setHeader3Done] = useState(false);

    const templateRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        // Safely check if the right side element exists
        const rightSide = templateRef.current.querySelector(".rightSide");
        if (rightSide) {
          rightSide.style.transform = `translateY(${message.templateYScroll}px)`;
        }
      }
    }, [message]);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, [templateOrderIndex]);

    const [templateColour, setTemplateColour] = useState("white");
    useEffect(() => {
      if (testingTemplates) {
        if (templateColour === "white") {
          setVersion("white");
        }
        if (templateColour === "dark") {
          setVersion("dark");
        }
        if (templateColour === "grey") {
          setVersion("grey");
        }
      }
    }, [templateColour, testingTemplates]);

    return (
      <SharedTemplate
        id="xthings-inspire"
        textColor={version}
        className="rounded-t-[1.5rem]"
      >
        <div className="overflow-hidden rounded-t-[1.5rem]">
          <Grid customClasses="gap-x-4 desktop:!mx-0 relative x-inspire rounded-t-[1.5rem]">
            <div className="mobile:col-start-1 desktop:col-start-1 mobile:col-span-full desktop:col-end-13 mobile:row-start-1 z-30">
              {!content.imgURL ? (
                <>
                  <div className="mobile:hidden tablet:hidden desktop:block">
                    <ImageSkeleton display="desktop" />
                  </div>
                  <div className="mobile:block desktop:hidden">
                    <ImageSkeleton display="mobile" />
                  </div>
                </>
              ) : (
                <>
                  {!content.imgURL ? (
                    <div className="mobile:rounded-tl-[1.5rem] desktop:rounded-tl-[1.5rem] desktop:rounded-tr-[0rem] w-full mobile:h-[450px] tablet:h-[450px] desktop:h-screen max-h-full bg-gradient-to-r from-cyan-500 to-blue-500"></div>
                  ) : (
                    <div className="relative w-full h-full">
                      <div
                        id="xthings-inspire-image-overlay"
                        className="mobile:rounded-t-[1.5rem] desktop:rounded-tl-[1.5rem] desktop:rounded-tr-[0rem] absolute top-0 left-0 w-full h-full min-h-full max-h-full bg-black/60"
                      ></div>
                      <div
                        id="xthings-inspire-main-image"
                        style={{
                          backgroundImage: `linear-gradient(
                                0deg,
                                rgba(0, 0, 0, 0) 0%,
                                rgba(0, 0, 0, 0.4) 100%
                            ),url(${content.imgURL})`,
                        }}
                        className={`mobile:rounded-t-[1.5rem] desktop:rounded-tl-[1.5rem] desktop:rounded-tr-[0rem] w-full desktop:min-h-screen max-h-full bg-cover bg-center bg-no-repeat`}
                      ></div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              id="xthings-inspire-header-content"
              className="z-40 mobile:pb-[4.5rem] desktop:pb-0 desktop:flex desktop:flex-col h-full mobile:col-start-1 mobile:col-span-full desktop:col-span-10 desktop:col-start-2 mobile:row-start-1 desktop:pt-[5.25rem] mobile:pt-[2.625rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
            >
              <div className="">
                <h2
                  id="tagline"
                  className="mobile:text-[1rem] desktop:text-lg font-medium text-white uppercase"
                >
                  {query}
                </h2>
                {testingTemplates && (
                  <ColourSelect
                    templateColour={templateColour}
                    setTemplateColour={setTemplateColour}
                  ></ColourSelect>
                )}
                <div className="pt-[36px]">
                  {!content.header ? (
                    <TitleSkeleton />
                  ) : (
                    <h3
                      id="xthings-inspire-header"
                      className="mobile:text-4xl desktop:text-[40px] font-bold text-white"
                    >
                      {content.header}
                    </h3>
                  )}
                </div>
              </div>
            </div>
            <div
              ref={templateRef}
              id="xthings-inspire-paragraph-content"
              className="rightSide mt-[-21px] mobile:row-start-2 desktop:row-start-1 mobile:col-start-1 desktop:col-start-13 mobile:col-span-full desktop:col-end-24 flex flex-col mobile:px-8 tablet:px-[3.125rem] desktop:pt-[5.25rem] desktop:pr-8 desktop:pb-[8.5rem]"
            >
              {" "}
              {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
              <div className="mobile:pt-[36px] tablet:pt-[1.375rem] desktop:pt-0">
                {!content.paragraphs[0] ? (
                  <OneLineQuestionSkeleton />
                ) : (
                  <p
                    className={classnames(
                      "mobile:text-xl desktop:text-2xl desktop:mb-2 mobile:mb-[36px]",
                      {
                        "!text-text-greyHeader":
                          version === "white" || version === "grey",
                        "!text-white": version === "dark",
                      }
                    )}
                  >
                    <TypewriterText
                      hasAllText
                      text={content.paragraphs[0].header}
                      type="strong"
                      isDone={header1Done}
                      setIsDone={setHeader1Done}
                    />
                  </p>
                )}
                {!content.paragraphs[0]?.text ? (
                  <ParagraphSkeleton />
                ) : (
                  header1Done && (
                    <TypewriterText
                      id="xthings-inspire-paragraph-1"
                      className="mobile:text-sm desktop:text-lg font-light col-span-6"
                      hasAllText={content.paragraphs[0].done}
                      text={content.paragraphs[0].text}
                      isDone={para1Done}
                      setIsDone={setPara1Done}
                    />
                  )
                )}
              </div>
              <div className="mobile:pt-[36px] tablet:pt-[36px] desktop:pt-[2.25rem]">
                {!content.paragraphs[1] ? (
                  <OneLineQuestionSkeleton />
                ) : (
                  para1Done && (
                    <p
                      className={classnames(
                        "mobile:text-xl desktop:text-2xl desktop:mb-2 mobile:mb-[36px]",
                        {
                          "!text-text-greyHeader":
                            version === "white" || version === "grey",
                          "!text-white": version === "dark",
                        }
                      )}
                    >
                      <TypewriterText
                        hasAllText
                        text={content.paragraphs[1].header}
                        type="strong"
                        isDone={header2Done}
                        setIsDone={setHeader2Done}
                      />
                    </p>
                  )
                )}
                {!content.paragraphs[1]?.text ? (
                  <ParagraphSkeleton />
                ) : (
                  header2Done && (
                    <TypewriterText
                      id="xthings-inspire-paragraph-2"
                      className="mobile:text-sm desktop:text-lg font-light col-span-6"
                      hasAllText={content.paragraphs[1].done}
                      text={content.paragraphs[1].text}
                      isDone={para2Done}
                      setIsDone={setPara2Done}
                    />
                  )
                )}
              </div>
              <div className="mobile:pt-[1.5rem] tablet:pt-[1.375rem] desktop:pt-[2.25rem]">
                {!content.paragraphs[2] ? (
                  <OneLineQuestionSkeleton />
                ) : (
                  para2Done && (
                    <p
                      className={classnames(
                        "mobile:text-xl desktop:text-2xl desktop:mb-2 mobile:mb-[36px]",
                        {
                          "!text-text-greyHeader":
                            version === "white" || version === "grey",
                          "!text-white": version === "dark",
                        }
                      )}
                    >
                      <TypewriterText
                        hasAllText
                        text={content.paragraphs[2].header}
                        type="strong"
                        isDone={header3Done}
                        setIsDone={setHeader3Done}
                      />
                    </p>
                  )
                )}
                {!content.paragraphs[2]?.text ? (
                  <ParagraphSkeleton />
                ) : (
                  header3Done && (
                    <TypewriterText
                      id="xthings-inspire-paragraph-2"
                      className="mobile:text-sm desktop:text-lg font-light col-span-6"
                      hasAllText={content.paragraphs[2].done}
                      text={content.paragraphs[2].text}
                      isDone={para3Done}
                      setIsDone={setPara3Done}
                    />
                  )
                )}
              </div>
              {para3Done && (
                <Citations
                  metadata={response.metadata}
                  noGrid
                  version={version}
                  usedSnippetIds={response.used_snippet_ids}
                />
              )}
              {para3Done && (
                <MediaLinksV2
                  assets={assets}
                  display="block"
                  version={version}
                />
              )}
            </div>
          </Grid>
        </div>
      </SharedTemplate>
    );
  }
);
