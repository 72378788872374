import { createBrowserRouter } from "react-router-dom";
import { Root } from "./root";
import MainApp from "./pages/MainApp/MainApp";
import Streaming from "./pages/Streaming/Streaming";
import VideoTest from "./pages/VideoTest";
import "./App.css";
import ThemeSelector from "./ThemeSelector";
import InitialNew from "./pages/Initial/InitialNew";
import DefaultLayout from "./layouts";

export const router = createBrowserRouter([
  {
    path: "/templates",
    element: (
      <Root>
        <ThemeSelector>
          <MainApp />
        </ThemeSelector>
      </Root>
    ),
  },
  {
    path: "/streaming",
    element: (
      <Root>
        <ThemeSelector>
          <Streaming />
        </ThemeSelector>
      </Root>
    ),
  },
  {
    path: "/",
    element: (
      <Root>
        <ThemeSelector>
          <DefaultLayout />
        </ThemeSelector>
      </Root>
    ),
  },
  {
    path: "/entry",
    element: (
      <Root>
        <ThemeSelector>
          <InitialNew />
        </ThemeSelector>
      </Root>
    ),
  },
  {
    path: "/video-test",
    element: (
      <Root>
        <ThemeSelector>
          <VideoTest />
        </ThemeSelector>
      </Root>
    ),
  },
]);
