import React from "react";
import ContentRailCarousel from "./ContentRailCarousel";
import { EmblaOptionsType } from "embla-carousel";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useChat } from "../../context/chat-context";
import { FOLLOW_UP_HEIGHT } from "../../constants";

export const ContentRail = () => {
  const { latestMetadata } = useChat();

  if (!latestMetadata || latestMetadata.length === 0) {
    return null;
  }

  const OPTIONS: EmblaOptionsType = { containScroll: false };
  const SLIDES = latestMetadata;
  const isMobile = useMediaQuery("(max-width: 480px)");

  return (
    <div
      className="px-20"
      style={{ paddingBottom: `${FOLLOW_UP_HEIGHT + 100}px` }}
    >
      {isMobile && (
        <div className="relative z-50 col-start-1 col-span-full  pt-[35px] pb-[100px]">
          <h3 className="text-lg font-bold uppercase tablet:px-[50px] mobile:px-[22px] pb-[35px]">
            Based on your last question, we recommend the following pages
          </h3>
          <ContentRailCarousel slides={SLIDES} options={OPTIONS} />
        </div>
      )}

      {!isMobile && (
        <div className="relative z-50 col-start-1 desktop:col-start-2 col-span-full">
          <h3 className="text-lg font-bold uppercase ">
            Based on your last question, we recommend the following pages
          </h3>
          <div className="flex overflow-y-auto no-scrollbar flex-row space-x-[28px] pt-[44px]">
            {latestMetadata.map((item) => {
              return (
                <a
                  href={item.page_url}
                  target="_blank"
                  className="mx-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white"
                  aria-label={item.page_title}
                  rel="noreferrer"
                >
                  <div className="w-[263px]">
                    <div
                      style={{ backgroundImage: `url(${item.thumbnail})` }}
                      className="w-full h-[156px] min-w-[263px] bg-no-repeat bg-cover bg-center mb-8"
                      title={item.page_title}
                    />
                    <h3 className="text-lg  mb-2 font-bold">
                      {item.page_title}
                    </h3>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
