import { MutableRefObject, useEffect, useRef, useState } from "react";
import { MAX_Z_DISTANCE, PADDING_TOP } from "../constants";
import RetrieveTemplate from "../templates/RetrieveTemplate";
import { Question } from "../interface";
import ScrollForwarder from "../utils/scrollForwarder";
interface TemplateWrapperProps {
  scrollTop: number;
  messageRef: MutableRefObject<{
    templateYScroll: number;
  }>;
  individualScrollHeights: number[];
  id: number;
  getWrapperRef: () => void;
  onMount: (
    wrapperElement: any,
    templateElement: any,
    setHeightsVersion: any
  ) => void;
  question: Question;
}

const TemplateWrapper = ({
  scrollTop,
  question,
  onMount,
  scrollContainerRef,
  getWrapperRef,
  id,
  individualScrollHeights,
}: TemplateWrapperProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [heightsVersion, setHeightsVersion] = useState(0);
  const messageRef = useRef({ templateYScroll: 0 });

  useEffect(() => {
    if (onMount && wrapperRef.current) {
      getWrapperRef(wrapperRef.current);
      onMount(
        wrapperRef.current,
        wrapperRef.current.children[0],
        setHeightsVersion,
        individualScrollHeights
      );
    }
  }, [getWrapperRef, individualScrollHeights, onMount]);

  useEffect(() => {
    if (wrapperRef.current) {
      const templateWrapper = wrapperRef.current;
      const firstChild = templateWrapper.firstElementChild;
      const templateHeight = templateWrapper.offsetHeight;
      const innerOffset =
        firstChild?.offsetHeight - templateHeight - PADDING_TOP;

      const i = id - 1; // Assuming IDs are sequential starting from 1
      const startHeight = individualScrollHeights.current
        .slice(0, i)
        .reduce((a, b) => a + b, 0);
      const endHeight = startHeight + individualScrollHeights.current[i];

      const newMessage = { templateYScroll: 0 };

      // Calculate visibility based on scroll position
      let isTemplateVisible = true;

      if (scrollTop >= startHeight && scrollTop <= endHeight) {
        let diff = templateHeight - (scrollTop - startHeight + PADDING_TOP);

        if (diff < PADDING_TOP) diff = PADDING_TOP;

        if (id === 1 && diff > 0) {
          templateWrapper.style.transform = `translate(-50%, 0)`;
        } else {
          templateWrapper.style.transform = `translate(-50%, ${diff}px)`;
        }

        if (
          scrollTop > startHeight + templateHeight &&
          scrollTop <= endHeight
        ) {
          let offsetWithinTemplate = scrollTop - (startHeight + templateHeight);
          if (offsetWithinTemplate > innerOffset)
            offsetWithinTemplate = innerOffset;
          newMessage.templateYScroll = -offsetWithinTemplate;
        } else {
          newMessage.templateYScroll = 0;
        }
      } else {
        if (scrollTop < startHeight) {
          templateWrapper.style.transform = `translate(-50%, ${templateHeight}px) translateZ(${templateHeight}px)`;
          newMessage.templateYScroll = -innerOffset - PADDING_TOP;
          isTemplateVisible = false;
        } else if (scrollTop > endHeight) {
          let zDistance = (scrollTop - endHeight) / 10;

          // Cap zDistance to MAX_Z_DISTANCE and update visibility
          if (zDistance > MAX_Z_DISTANCE) {
            zDistance = MAX_Z_DISTANCE;
            isTemplateVisible = false;
          } else {
            isTemplateVisible = true;
          }

          templateWrapper.style.transform = `translate(-50%, ${PADDING_TOP}px) translateZ(${-zDistance}px)`;
          newMessage.templateYScroll = -innerOffset;
        }
      }

      // Update visibility using functional state update
      setIsVisible((prevVisible) => {
        if (prevVisible !== isTemplateVisible) {
          return isTemplateVisible;
        }
        return prevVisible;
      });

      // Update the messageRef only if the message has actually changed
      if (JSON.stringify(messageRef.current) !== JSON.stringify(newMessage)) {
        messageRef.current = newMessage;
      }
    }
  }, [scrollTop, individualScrollHeights, id, heightsVersion]);

  const wrapperStyle = {
    visibility: isVisible ? 1 : 0,
  };

  return (
    <div
      className="template_wrapper rounded-t-3xl"
      id={`template${id}`}
      ref={wrapperRef}
      style={wrapperStyle}
    >
      <ScrollForwarder scrollableRef={scrollContainerRef}>
        <RetrieveTemplate
          question={question}
          index={id - 1}
          messageRef={messageRef}
        />
      </ScrollForwarder>
    </div>
  );
};

export default TemplateWrapper;
