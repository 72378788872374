import { Grid } from "../../../components";
import { TitleSkeleton, ParagraphSkeleton } from "../../../components/Skeleton";
import Citations from "../../../components/Citations/Citations";
import { forwardRef, useEffect, useRef, useState } from "react";
import { QuestionResponse } from "../../../interface";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import SharedTemplate from "../../../layouts/SharedTemplate";

interface XThingsHeroProps {
  templateOrderIndex: number;
  loading: boolean;
  response: QuestionResponse;
  message: {
    templateYScroll: number;
  };
}

export const XThingsHero = forwardRef(
  ({ templateOrderIndex, response, loading, message }: XThingsHeroProps) => {
    const { content, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [para3Done, setPara3Done] = useState(false);
    const [header1Done, setHeader1Done] = useState(false);
    const [header2Done, setHeader2Done] = useState(false);
    const [header3Done, setHeader3Done] = useState(false);
    const templateRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    return (
      <SharedTemplate
        id="xthings-hero"
        className={classNames("desktop:rounded-t-[1.5rem]", {
          "mobile:rounded-t-[1.5rem]": templateOrderIndex !== 0,
        })}
        templateRef={templateRef}
      >
        <div className="bg-gradient-to-b from-[#E0CCFF] from-60% to-white mobile:h-full bg-no-repeat bg-center bg-cover relative rounded-t-[1.5rem]">
          <div className="">
            <div className=" desktop:min-h-full rounded-t-[1.5rem]">
              <div>
                <Grid customClasses="mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:!mx-0 gap-x-4">
                  <div className="desktop:mt-[74px] mt-[42px] mobile:col-start-1 desktop:col-start-2 col-span-full rounded-t-[1.5rem]">
                    <h2 className="mobile:text-[1rem] desktop:text-lg text-black text-base font-medium uppercase">
                      {query}
                    </h2>
                    <div className="desktop:mt-[36px] mt-[24px]">
                      {!content.header ? (
                        <TitleSkeleton rows={1} />
                      ) : (
                        <h3 className="mobile:text-4xl tablet:text-[40px] text-black text-[40px] font-bold">
                          {content.header}
                        </h3>
                      )}
                    </div>
                  </div>

                  <div className="desktop:flex mobile:mt-[72px] desktop:mt-[72px] desktop:space-x-[50px] mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-24 justify-between">
                    <div className="mobile:mb-[72px] mobile:p-[44px] tablet:px-[116px] tablet:py-[64px] desktop:p-[44px] rounded-lg mobile:min-h-[200px] desktop:min-h-[322px] desktop:w-[450px] bg-grey-200/95 flex flex-col grow items-start ">
                      {!content.paragraphs[0] ? (
                        <TitleSkeleton />
                      ) : (
                        <h4 className="desktop:text-2xl mobile:text-xl font-normal desktop:min-h-20 !text-text-greyHeader">
                          <TypewriterText
                            hasAllText
                            text={content.paragraphs[0].header}
                            type="strong"
                            isDone={header1Done}
                            setIsDone={setHeader1Done}
                          />
                        </h4>
                      )}
                      {!content.paragraphs[0]?.text ? (
                        <ParagraphSkeleton />
                      ) : (
                        header1Done && (
                          <TypewriterText
                            id="xthings-hero-paragraph-0"
                            className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 !text-text-greyText"
                            hasAllText={content.paragraphs[0].done}
                            text={content.paragraphs[0].text}
                            isDone={para1Done}
                            setIsDone={setPara1Done}
                          />
                        )
                      )}
                    </div>
                    <div className="mobile:mb-[72px] mobile:p-[44px] tablet:px-[116px] tablet:py-[64px] desktop:p-[44px] rounded-lg bg-grey-200/95 mobile:min-h-[200px] desktop:min-h-[322px] desktop:w-[450px] flex flex-col grow items-start">
                      {!content.paragraphs[1] ? (
                        <TitleSkeleton />
                      ) : (
                        para1Done && (
                          <h5 className="desktop:text-2xl mobile:text-xl font-normal desktop:min-h-20 !text-text-greyHeader">
                            <TypewriterText
                              hasAllText
                              text={content.paragraphs[1].header}
                              type="strong"
                              isDone={header2Done}
                              setIsDone={setHeader2Done}
                            />
                          </h5>
                        )
                      )}
                      {!content.paragraphs[1]?.text ? (
                        <ParagraphSkeleton />
                      ) : (
                        header2Done && (
                          <TypewriterText
                            id="xthings-hero-paragraph-1"
                            className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 !text-text-greyText"
                            hasAllText={content.paragraphs[1].done}
                            text={content.paragraphs[1].text}
                            isDone={para2Done}
                            setIsDone={setPara2Done}
                          />
                        )
                      )}
                    </div>
                    <div className="mobile:mb-[72px] rounded-lg mobile:p-[44px] tablet:px-[116px] tablet:py-[64px] desktop:p-[44px] bg-grey-200/95 mobile:min-h-[200px] desktop:min-h-[322px] desktop:w-[450px] flex flex-col grow items-start ">
                      {!content.paragraphs[2] ? (
                        <TitleSkeleton />
                      ) : (
                        para2Done && (
                          <h6 className="mobile:text-xl desktop:text-2xl font-normal desktop:min-h-20 !text-text-greyHeader">
                            <TypewriterText
                              hasAllText
                              text={content.paragraphs[2].header}
                              type="strong"
                              isDone={header3Done}
                              setIsDone={setHeader3Done}
                            />
                          </h6>
                        )
                      )}
                      {!content.paragraphs[2]?.text ? (
                        <ParagraphSkeleton />
                      ) : (
                        header3Done && (
                          <TypewriterText
                            id="xthings-hero-paragraph-2"
                            className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 !text-text-greyText"
                            hasAllText={content.paragraphs[2].done}
                            text={content.paragraphs[2].text}
                            isDone={para3Done}
                            setIsDone={setPara3Done}
                          />
                        )
                      )}
                    </div>
                  </div>
                </Grid>
                {para1Done && (
                  <Citations
                    metadata={response.metadata}
                    version="white"
                    usedSnippetIds={response.used_snippet_ids}
                  />
                )}
                {para1Done && (
                  <MediaLinksV2
                    assets={assets}
                    display="fullwidth"
                    version="white"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </SharedTemplate>
    );
  }
);
