import React from "react";
import { Button, Grid, TypewriterText } from "..";
import { ArrowRightIcon, StaticSpinningCircles } from "../Icons";
import { MutableRefObject, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { OneLineQuestionSkeleton } from "../Skeleton";
import { QuestionSkeletonFullWidth } from "../Skeleton/QuestionSkeletonFullWidth";
import { useChat } from "../../context/chat-context";
import { QuestionResponse } from "../../interface";
import { FOLLOW_UP_HEIGHT } from "../../constants";

interface FollowUpFullWidthProps {
  placeholder?: string;
  style: "default" | "darkTxt" | "lightTxt";
  isLoading?: boolean;
  onSubmit: (value: string, is_selected_follow_up?: boolean) => void;
  backgroundColour: string;
  followupContainerRef: MutableRefObject<null>;
  animationClass?: string;
  templateCounter: number;
}

export const FollowUpFullWidth = ({
  animationClass,
  followupContainerRef,
  onSubmit,
  placeholder = "Ask a follow up or new question",
  backgroundColour = "background-brand",
  isLoading,
  templateCounter,
}: FollowUpFullWidthProps) => {
  const { loading: streaming, questions } = useChat();
  const [chosenFollowUpQuestion, setChosenFollowUpQuestion] = useState("");
  const [value, setValue] = useState("");
  const [buttonTextDone, setButtonTextDone] = useState(false);
  const [optionQuestionDone, setOptionQuestionDone] = useState(false);
  const [currentResponse, setCurrentResponse] = useState<QuestionResponse>();

  useEffect(() => {
    if (questions.length && templateCounter) {
      const counter = !templateCounter ? templateCounter : templateCounter - 1;
      const response = questions[counter].response;
      setCurrentResponse(response);
    }
  }, [questions]);

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(value);
    setValue("");
  };

  return (
    <div
      ref={followupContainerRef}
      id="question-component"
      data-testid="submit-question"
      style={{ height: `${FOLLOW_UP_HEIGHT}px` }}
      className={`bg-${backgroundColour} ${animationClass} hidden rounded-t-[1.5rem] followupsContainer pointer-events-none relative`}
    >
      <div>
        <Grid
          customClasses={`bg-${backgroundColour} rounded-t-[1.5rem] gap-x-4 h-full relative question-component pt-[2.25rem] mobile:pb-[3.125rem] desktop:pb-[4rem]`}
        >
          <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-end-12 flex desktop:flex-row mobile:flex-col mobile:pb-[2rem] tablet:pb-[1.875rem] desktop:pb-0 mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0">
            <div className="mobile:self-center tablet:self-start tablet:-ml-4 desktop:-ml-6">
              <StaticSpinningCircles />
            </div>
            {isLoading ? (
              <OneLineQuestionSkeleton />
            ) : (
              <>
                {currentResponse?.userQuestion && (
                  <TypewriterText
                    id="optional-returned-question"
                    className="text-lg font-bold mobile:pt-[5px] tablet:pt-[1.5rem] desktop:pt-[0.625rem] desktop:pb-0 desktop:self-start text-white"
                    text={currentResponse.userQuestion}
                    isDone={optionQuestionDone}
                    setIsDone={setOptionQuestionDone}
                    hasAllText
                  />
                )}
                {currentResponse &&
                  currentResponse.userQuestion.length <= 0 && (
                    <TypewriterText
                      id="optional-returned-question"
                      className="mobile:text-[1.125rem] tablet:text-[1.25rem] font-normal mobile:py-[1.75rem] desktop:pt-[0.625rem] desktop:pb-0 desktop:self-start text-white"
                      text="You might be interested in:"
                      isDone={optionQuestionDone}
                      setIsDone={setOptionQuestionDone}
                      hasAllText
                    />
                  )}
              </>
            )}
          </div>
          <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-13 desktop:col-end-24 mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0">
            <div>
              {isLoading ? (
                <div className="pb-[1.5rem]">
                  <QuestionSkeletonFullWidth />
                </div>
              ) : (
                <>
                  <div className="flex flex-col">
                    <div className="w-full">
                      {currentResponse?.followUps?.map((text, i) => (
                        <Button
                          key={i}
                          style={
                            chosenFollowUpQuestion === text
                              ? "active"
                              : "primary"
                          }
                          text={text}
                          typewriter={{
                            id: "question-box-buttons",
                            isDone: buttonTextDone,
                            setIsDone: setButtonTextDone,
                            textReady: optionQuestionDone,
                          }}
                          onClick={() => {
                            // don't allow same question twice - may want a disabled style
                            if (streaming || chosenFollowUpQuestion === text) {
                              return;
                            }
                            setChosenFollowUpQuestion(text);
                            onSubmit(text, true);
                          }}
                          classes="!mr-[12px] mobile:mr-[0] mb-[0.75rem] mobile:text-left px-2 rounded-xl border pointer-events-auto"
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
            {
              <div className="mobile:pt-[0.75rem] desktop:pt-[1.25rem] mobile:pb-[1.5rem] desktop:pb-0">
                <label
                  htmlFor="ask"
                  className="sr-only text-[#D6D6EB]"
                  aria-label="Ask a follow up or new question"
                >
                  Ask a follow up or new question
                </label>
                <form className="relative flex gap-1" onSubmit={handleSubmit}>
                  <input
                    aria-label="Search"
                    type="text"
                    name="ask"
                    id="ask"
                    className="text-[#D6D6EB] placeholder-white placeholder-opacity-50 mobile:text-sm tablet:text-lg block w-full border-0 !bg-transparent focus:bg-transparent focus:outline-none leading-4 pointer-events-auto"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                  />
                  <div className="flex gap-2 justify-end bottom-1">
                    <button
                      id="question-btn"
                      onClick={handleSubmit}
                      disabled={isEmpty(value)}
                      aria-label="Enter"
                      className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:rounded pointer-events-auto"
                    >
                      <ArrowRightIcon
                        arrowColour="fill-icons-arrowright"
                        data-testid="arrow-button"
                      />
                    </button>
                  </div>
                  <div
                    className="absolute inset-x-0 bottom-0 top-[30px] border-t border-neutral-400 peer-focus:border-t-2"
                    aria-hidden="true"
                  />
                </form>
              </div>
            }
          </div>
        </Grid>
      </div>
    </div>
  );
};
