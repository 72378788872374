import { forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import { QuestionResponse } from "../../../interface";
import {
  TitleSkeleton,
  ParagraphSkeleton,
  OneLineQuestionSkeleton,
} from "../../../components/Skeleton";
import Citations from "../../../components/Citations/Citations";
import classnames from "classnames";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";

import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import ColourSelect from "../../../components/ColourSelect/ColourSelect";
import SharedTemplate from "../../../layouts/SharedTemplate";

interface XThingsInformProps {
  templateOrderIndex: number;
  loading: boolean;
  response: QuestionResponse;
  testingTemplates: boolean;
  message: {
    templateYScroll: number;
  };
}

export const XThingsInform = forwardRef(
  ({
    templateOrderIndex,
    response,
    message,
    loading,
    testingTemplates,
  }: XThingsInformProps) => {
    const [version, setVersion] = useState("white");
    const { content, query, assets } = response;

    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [para3Done, setPara3Done] = useState(false);
    const [header1Done, setHeader1Done] = useState(false);
    const [header2Done, setHeader2Done] = useState(false);
    const [header3Done, setHeader3Done] = useState(false);
    const templateRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (message?.templateYScroll !== undefined && templateRef.current) {
        templateRef.current.style.transform = `translateY(${message.templateYScroll}px)`;
      }
    }, [message]);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, [templateOrderIndex]);

    const [templateColour, setTemplateColour] = useState("white");
    useEffect(() => {
      if (testingTemplates) {
        if (templateColour === "white") {
          setVersion("white");
        }
        if (templateColour === "dark") {
          setVersion("dark");
        }
        if (templateColour === "grey") {
          setVersion("grey");
        }
      }
    }, [templateColour, testingTemplates]);

    return (
      <SharedTemplate
        id="xthings-inform"
        textColor={version}
        templateRef={templateRef}
        className="rounded-t-[1.5rem] bg-grey-1000"
      >
        <div className="rounded-t-[1.5rem]">
          <div>
            <Grid
              customClasses={classnames(
                "gap-x-4 relative desktop:!mx-0 rounded-t-[3.125rem]"
              )}
            >
              <div
                id="xthings-inform-tagline-container"
                className="z-30 mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-10 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
              >
                <h2
                  id="xthings-inform-tagline"
                  className="mobile:text-[1rem] desktop:text-lg font-medium uppercase"
                >
                  {query}
                </h2>
                {testingTemplates && (
                  <ColourSelect
                    templateColour={templateColour}
                    setTemplateColour={setTemplateColour}
                  ></ColourSelect>
                )}
              </div>
              <div
                id="xthings-inform-content"
                className="h-1/2 mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-span-8 mobile:pt-[36px] desktop:pt-[2.25rem] desktop:pr-8 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:flex desktop:flex-col desktop:pb-[2.5rem]"
              >
                {!content.header ? (
                  <TitleSkeleton rows={1} />
                ) : (
                  <h3
                    id="xthings-inform-header"
                    className="mobile:text-4xl desktop:text-[40px] font-bold"
                  >
                    {content.header}
                  </h3>
                )}
              </div>

              <div
                id="xthings-inform-answer-content"
                className="mobile:col-start-1 desktop:col-start-11 mobile:col-span-full desktop:col-end-24 mobile:pt-[36px] tablet:pt-[36px] desktop:pt-[36px] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0 desktop:pb-[2.5rem]"
              >
                <div className="grid desktop:grid-cols-13 gap-x-4">
                  <div className="mobile:col-start-1 mobile:col-span-full desktop:col-span-6 modile:row-start-1 ">
                    {!content.paragraphs[0] ? (
                      <OneLineQuestionSkeleton />
                    ) : (
                      <p className="mobile:text-xl desktop:text-2xl">
                        <TypewriterText
                          hasAllText
                          text={content.paragraphs[0].header}
                          type="strong"
                          isDone={header1Done}
                          setIsDone={setHeader1Done}
                        />
                      </p>
                    )}
                  </div>
                  <div className="mobile:col-start-1 mobile:col-span-full desktop:col-span-6 mobile:row-start-2 desktop:row-start-2">
                    {!content.paragraphs[0]?.text ? (
                      <ParagraphSkeleton />
                    ) : (
                      header1Done && (
                        <TypewriterText
                          id="xthings-inform-paragraph-0"
                          className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 mobile:pt-[36px] desktop:pt-[1.375rem]"
                          hasAllText={content.paragraphs[0].done}
                          text={content.paragraphs[0].text}
                          isDone={para1Done}
                          setIsDone={setPara1Done}
                        />
                      )
                    )}
                  </div>

                  <div className="mobile:col-start-1 desktop:col-start-8 desktop:col-span-6 mobile:col-span-full mobile:row-start-3 desktop:row-start-1 mobile:pt-[36px] desktop:pt-0">
                    {!content.paragraphs[1] ? (
                      <OneLineQuestionSkeleton />
                    ) : (
                      para1Done && (
                        <p className="mobile:text-xl desktop:text-2xl">
                          <TypewriterText
                            hasAllText
                            text={content.paragraphs[1].header}
                            type="strong"
                            isDone={header2Done}
                            setIsDone={setHeader2Done}
                          />
                        </p>
                      )
                    )}
                  </div>
                  <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-8 desktop:col-span-6 mobile:row-start-4 desktop:row-start-2">
                    {!content.paragraphs[1]?.text ? (
                      <ParagraphSkeleton />
                    ) : (
                      header2Done && (
                        <TypewriterText
                          id="xthings-inform-paragraph-1"
                          className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 mobile:pt-[36px] desktop:pt-[1.375rem]"
                          hasAllText={content.paragraphs[1].done}
                          text={content.paragraphs[1].text}
                          isDone={para2Done}
                          setIsDone={setPara2Done}
                        />
                      )
                    )}
                  </div>

                  <div className="mobile:col-start-1 mobile:col-span-full desktop:col-span-6 mobile:row-start-5 desktop:row-start-3 mobile:pt-[36px] desktop:pt-[2.25rem]">
                    {content.paragraphs[2] && para2Done && (
                      <p className="mobile:text-xl desktop:text-2xl">
                        <TypewriterText
                          hasAllText
                          text={content.paragraphs[2].header}
                          type="strong"
                          isDone={header3Done}
                          setIsDone={setHeader3Done}
                        />
                      </p>
                    )}
                  </div>
                  <div className="mobile:col-start-1 mobile:col-span-full desktop:col-span-6 mobile:row-start-6 desktop:row-start-4">
                    {content.paragraphs[2]?.text && header3Done && (
                      <TypewriterText
                        id="xthings-inform-paragraph-2"
                        className="mobile:text-sm desktop:text-lg font-light desktop:col-span-6 mobile:pt-[36px] desktop:pt-[1.375rem]"
                        hasAllText={content.paragraphs[2].done}
                        text={content.paragraphs[2].text}
                        isDone={para3Done}
                        setIsDone={setPara3Done}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            {para2Done && (
              <Citations
                metadata={response.metadata}
                version={version}
                usedSnippetIds={response.used_snippet_ids}
              />
            )}
            {para2Done && (
              <MediaLinksV2
                assets={assets}
                display="fullwidth"
                version={version}
              />
            )}
          </div>
        </div>
      </SharedTemplate>
    );
  }
);
