import React from "react";

interface VideoClipProps {
  colour: string;
}

export const VideoClip = ({ colour }: VideoClipProps) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Video Clip">
      <path
        className={colour}
        id="Shape"
        d="M8 8.40744V12.5928C8 13.0484 8.48794 13.3378 8.88778 13.1192L12.7167 11.0263C13.133 10.7988 13.133 10.2009 12.7166 9.97336L8.88773 7.88093C8.48789 7.66243 8 7.9518 8 8.40744ZM4.6 3.2998C3.16406 3.2998 2 4.46386 2 5.8998V15.0998C2 16.5357 3.16406 17.6998 4.6 17.6998H15.4C16.8359 17.6998 18 16.5357 18 15.0998V5.8998C18 4.46386 16.8359 3.2998 15.4 3.2998H4.6ZM3.2 5.8998C3.2 5.12661 3.8268 4.4998 4.6 4.4998H15.4C16.1732 4.4998 16.8 5.12661 16.8 5.8998V15.0998C16.8 15.873 16.1732 16.4998 15.4 16.4998H4.6C3.8268 16.4998 3.2 15.873 3.2 15.0998V5.8998Z"
      />
    </g>
  </svg>
);
