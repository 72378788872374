import React, { useState } from "react";
import { Cross } from "../../components/Icons";
import classnames from "classnames";
import { useConfig } from "../../context/config-context";
import classNames from "classnames";
import { useChat } from "../../context/chat-context";
import Loading from "./Loading";
import ClientIcon from "../../components/ClientIcon";

interface InterimProps {
  handleModalClose: () => void;
  onSubmit: (value: string) => void;
}

export default function Interim({ onSubmit, handleModalClose }: InterimProps) {
  const { config } = useConfig();
  const [value, setValue] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [startInitial, setStartInitial] = useState(false);
  const { loading, questions } = useChat();

  const animationClass = questions.length
    ? "animate-disappear invisible"
    : "animate-fade";

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  const submissionCall = (value: string) => {
    onSubmit(value);
    setValue("");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    submissionCall(value);
  };

  const handleQuestionClick = (value: string) => {
    submissionCall(value);
  };

  setTimeout(() => {
    setExpanded(true);
  }, 200);
  setTimeout(() => {
    setStartInitial(true);
  }, 1000);

  return (
    <>
      <div
        id="interim"
        className={classNames(
          "w-full text-white pointer-events-auto h-[calc(100vh-80px)] tablet:h-[600px] tablet:px-12 bottom-0 absolute",
          animationClass
        )}
      >
        <div className="bg-black/70 h-full rounded-t-3xl relative">
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="z-50 absolute top-4 right-6">
                <div className="flex">
                  <button
                    aria-label="Close"
                    onClick={handleModalClose}
                    className="smallscreenheight:pt-[10px] focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white cursor-pointer ml-2"
                  >
                    <Cross crossColour="fill-icons-crosslight" />
                  </button>
                </div>
              </div>
              <div className="flex flex-col content-center h-full w-full justify-center items-center animate-fade animate-delay-3000 opacity-0">
                <div className="w-full smallscreenheight:pt-[0] mobile:pt-[3.75rem] tablet:pt-[1.625rem] desktop:pt-[1.375rem] ">
                  <form
                    className="mobile:px-[1.375rem] tablet:px-[3.125rem] desktop:px-[5.375rem]"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex border-b-[1px] py-4 mobile:mt-[5px]">
                      <input
                        aria-label="Search"
                        className="animate-fade text-white placeholder-white flex w-full bg-transparent p4 mobile:text-xl desktop:text-2xl tablet:text-base outline-none"
                        placeholder="Ask me a question..."
                        onChange={onChange}
                        value={value}
                      />
                      <button
                        aria-label="Enter"
                        disabled={value.length === 0}
                        className={classnames(
                          "font-bold mobile:text-sm desktop:text-lg tablet:text-base focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white",
                          { "text-grey-500": !value }
                        )}
                      >
                        enter
                      </button>
                    </div>
                  </form>
                  <div
                    className={classnames(
                      "flex mobile:pt-[2.25rem] desktop:pt-[4.125rem] mobile:px-[2.5rem] tablet:px-[8.625rem] desktop:px-[21.75rem]",
                      { "flex-col": expanded }
                    )}
                  >
                    <div
                      className={classnames(
                        "flex h-[40px] relative w-full justify-center items-center"
                      )}
                    >
                      <ClientIcon />
                    </div>
                    <div className={classnames("flex w-full justify-center")}>
                      <div className="font-bold ml-4 mobile:ml-[0] text-[#BFBFBF] text-center mobile:text-xl desktop:text-2xl tablet:text-base pt-[1.75rem]">
                        Or I have a few suggestions to get started:
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classnames(
                    "desktop:grid desktop:grid-cols-3 tablet:grid-cols-3 gap-x-4 w-full mobile:px-[3.125rem] desktop:px-[5.375rem]"
                  )}
                >
                  {config.INITIAL_QUESTIONS.map((question) => (
                    <div
                      className="flex flex-row tablet:flex-col desktop:flex-col items-center"
                      key={question.replaceAll(" ", "")}
                    >
                      <div className="hidden">{question}</div>
                      <div className="flex w-full">
                        <button
                          aria-label={question}
                          onClick={() => handleQuestionClick(question)}
                          className="hover:text-white w-full pt-[2.25rem] text-[#BFBFBF] desktop:text-[1.063rem] tablet:text-sm mobile:text-sm desktop:text-lg font-normal text-center tablet:leading-[1.688rem] focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white"
                        >
                          <div className="animate-fade">{question}</div>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <p
                  className={classnames(
                    "smallscreenheight:pt-[3.75rem] text-[14px] leading-[1.5] text-[#BFBFBF] font-normal text-center pt-[2.5rem] mobile:pt-[72px] mobile:px-[3.125rem] tablet:px-[3.125rem] desktop:px-[8.25rem]",
                    {
                      "!text-[#BFBFBF] animate-fade": startInitial,
                    }
                  )}
                >
                  The responses provided by GXM are generated using artificial
                  intelligence (AI) and are based on the information available
                  on this website. While we strive for accuracy, the content may
                  not always reflect the most current or complete data. Please
                  verify critical information independently. By using this
                  service, you acknowledge and accept the WPP’s&nbsp;
                  <a
                    href="https://urldefense.com/v3/__https:/www.wpp.com/en-au/terms-and-conditions__;!!BupLon6U!qYfoxzGRG5KwHz517lMntK7HGfsfn1jAdYOspfROlPIG41y-M5slYOind6A1q_6nW9noWtuOEpNWntZV8CMakmmnGyFT%24"
                    target="_blank"
                    className="underline font-semibold"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href="https://urldefense.com/v3/__https:/www.wpp.com/en-au/privacy-policy__;!!BupLon6U!qYfoxzGRG5KwHz517lMntK7HGfsfn1jAdYOspfROlPIG41y-M5slYOind6A1q_6nW9noWtuOEpNWntZV8CMakvnkuVWl%24"
                    target="_blank"
                    className="underline font-semibold"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
