import React, { useState } from "react";

export default function VideoTest() {
  const [url, setUrl] = useState<string>("");

  return (
    <div className="h-full w-full block font-default relative">
      <div className="p-6">
        <label htmlFor="videoUrl">URL: </label>
        <input
          id="videoUrl"
          onChange={(e) => setUrl(e.target.value)}
          value={url}
          className="bg-grey-300 w-1/2"
          aria-label="Video URL"
        />
      </div>
      <div className="relative" style={{ paddingTop: "56.25%" }}>
        <iframe
          title="Video"
          className="absolute inset-0 w-full h-full"
          src={url}
          data-testid="video-iframe"
        ></iframe>
      </div>
    </div>
  );
}
