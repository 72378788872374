import React, { ReactNode } from "react";
import { MovingSpinningCircles } from "../Icons";

interface CLientTypes {
  spotify: ReactNode;
  wpp: ReactNode;
}

const client: CLientTypes = {
  spotify: (
    <div className="avatar">
      <img
        src="/spotify.png"
        alt="Spotify Logo"
        style={{ width: "50px", height: "50px" }}
      />
    </div>
  ),
  wpp: <MovingSpinningCircles />,
};

// Functional component to render the icon
const ClientIcon: React.FC = () => {
  const clientKey = process.env.REACT_APP_CLIENT as
    | keyof CLientTypes
    | undefined;

  return clientKey && client[clientKey] ? (
    client[clientKey]
  ) : (
    <MovingSpinningCircles />
  );
};

export default ClientIcon;
