import React from "react";
import useMediaQuery from "../../hooks/useMediaQuery";

export const Minimise = () => {
  const isMobile = useMediaQuery("(max-width: 480px)");

  return (
    <>
      {isMobile && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="minimise"
        >
          <g id="Minimize" className="minimise">
            <rect
              width="26"
              height="26"
              rx="13"
              fill="#F5F5F5"
              className="minimise"
            />
            <path
              id="Vector 14"
              d="M7 13H19"
              stroke="#808080"
              stroke-width="2"
              stroke-linecap="round"
              className="minimise"
            />
          </g>
        </svg>
      )}
      {!isMobile && (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="minimise"
        >
          <g id="Minimize" className="minimise">
            <rect
              width="26"
              height="26"
              rx="13"
              fill="#F5F5F5"
              className="minimise"
            />
            <path
              id="Vector 14"
              d="M7 13H19"
              stroke="#808080"
              stroke-width="2"
              stroke-linecap="round"
              className="minimise"
            />
          </g>
        </svg>
      )}
    </>
  );
};
