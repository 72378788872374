export type Config = {
  SITE_ID: string;
  CLIENT_ID: string;
  HOST: string;
  SATALIA_HOST: string;
  SATALIA_API_KEY: string;
  INITIAL_QUESTIONS: string[];
  BRAND_NAME: string;
};

export interface ReadableStreamReadResult {
  read: () => {
    done: boolean;
    value: string;
  };
}

export enum ChunkType {
  header = "header",
  chat_content = "chat_content",
  chat_finish = "chat_finish",
  user_question = "user_question",
  follow_ups = "follow_ups",
  request_finish = "request_finish",
}

interface ChunkBase {
  conversation_id: string;
  message_id: string;
  timestamp: string;
  user_id: string;
}

export interface ContextMetadataItem {
  doc_id: string;
  type: string;
  page_url: string;
  page_title: string;
  thumbnail: string;
  relevance: number;
}

export type AssetsData = {
  pdf: Asset[];
  video: Asset[];
  images: {
    square: ImageAsset;
    landscape: ImageAsset;
    portrait: ImageAsset[]; // weird
  };
};

export type Asset = {
  url: string;
  asset_id: string;
  metadata: Record<string, any>;
  relevance_score: number;
};

export type ImageAsset = {
  url: string;
  asset_id: string;
  metadata: {
    aspect_ratio: number;
  };
  relevance_score: number;
};

export interface HeaderChunk extends ChunkBase {
  chunk_type: ChunkType.header;
  data: {
    check_context: string;
    check_continuity: string;
    check_safety: string;
    decide_template: Template;
    headlines: string;
    used_snippets: string[];
    context_metadata: ContextMetadataItem[];
    image_data: {
      metadata: {
        PLACEHOLDER: string;
      };
      src: string;
    };
    assets_data: AssetsData;
  };
}

export interface ChatChunk extends ChunkBase {
  chunk_type: ChunkType.chat_content;
  data: string;
}

export interface UserQuestionChunk extends ChunkBase {
  chunk_type: ChunkType.user_question;
  data: string;
}

export interface FollowUpsChunk extends ChunkBase {
  chunk_type: ChunkType.follow_ups;
  data: string[];
}

export interface ChatFinishChunk extends ChunkBase {
  chunk_type: ChunkType.chat_finish;
  data: "stop";
}

export interface RequestFinishChunk extends ChunkBase {
  chunk_type: ChunkType.request_finish;
  data: "success" | "error";
}

export type Chunk =
  | HeaderChunk
  | ChatChunk
  | UserQuestionChunk
  | FollowUpsChunk
  | ChatFinishChunk
  | RequestFinishChunk;

export type QuestionResponseContent = {
  header?: string;
  tagline?: string;
  imgURL?: string;
  paragraphs: {
    header?: string;
    text: string;
    done: boolean;
  }[];
};

export enum Template {
  short = "short",
  long = "long",
  three_parts = "three_parts",
  video = "video",
  context_fail = "context_fail",
  safety_fail = "safety_fail",
  preset_answer = "preset_answer",
  // todo check how this really comes back... docs say this: content_filter_triggered: \{reason\}
  content_filter_triggered = "content_filter_triggered",
}

export type QuestionResponse = {
  messageId: string;
  query: string;
  template: Template;
  followUps: string[];
  userQuestion: string;
  content: QuestionResponseContent;
  metadata: ContextMetadataItem[];
  assets: AssetsData;
  newTopic: boolean;
  timestamp: string;
  used_snippet_ids: string[];
};

export type Question = {
  query: string;
  response: QuestionResponse;
};

export enum Flavour {
  hero = "hero",
  inspire = "inspire",
  inform = "inform",
  fail = "fail",
}

export type ChatHistory = {
  timestamp: string;
  query: string;
  answer: string;
}[];
