import { Dispatch, MutableRefObject, RefObject, SetStateAction } from "react";

export function tweenHeight(
  element: HTMLElement,
  heightIncrease: number,
  duration: number
): void {
  const startHeight = parseInt(window.getComputedStyle(element).height);
  let startTime: number | null = null;

  function animateHeight(timestamp: number): void {
    if (startTime === null) startTime = timestamp;
    const elapsed = timestamp - startTime;
    const progress = Math.min(elapsed / duration, 1);
    const currentHeight = startHeight + heightIncrease * progress;
    element.style.height = `${currentHeight}px`;

    if (progress < 1) {
      requestAnimationFrame(animateHeight);
    }
  }

  requestAnimationFrame(animateHeight);
}

export const calculateScrollDistance = (
  newTemplateId: number,
  scrollContainerRef: RefObject<HTMLElement>
): number => {
  const newTemplateWrapper = document.getElementById(
    `template${newTemplateId}`
  );
  if (!newTemplateWrapper || !scrollContainerRef.current) {
    return 0; // Handle cases where the elements are not found
  }

  const newTemplateTop = newTemplateWrapper.getBoundingClientRect().top;
  const containerTop = scrollContainerRef.current.getBoundingClientRect().top;
  return scrollContainerRef.current.scrollTop + (newTemplateTop - containerTop);
};

export const animateFollowup = (
  scrollDirection: "up" | "down",
  scrollBottom: boolean,
  followupContainerRef: RefObject<HTMLElement>
): void => {
  const followup = followupContainerRef.current;

  if (!followup) return; // Handle cases where the element is not found

  if (scrollDirection === "down" && scrollBottom) {
    followup.style.animation = "followup_moveUp 0.5s ease-in-out forwards";
    followup.classList.remove("hidden");
  } else if (scrollDirection === "up" && !scrollBottom) {
    followup.style.animation = "followup_moveDown 0.5s ease-in-out forwards";
  }
};

export const observeTemplateSize = (
  templateElement: HTMLElement,
  templateWrappers: MutableRefObject<(HTMLElement | null)[]>,
  individualScrollHeights: MutableRefObject<number[]>,
  setHeightsVersion: Dispatch<SetStateAction<number>>,
  scrollContainerRef: RefObject<HTMLElement>,
  handleScroll: () => void
): void => {
  const resizeObserver = new ResizeObserver(() => {
    const templateWrapper = templateElement.parentElement as HTMLElement | null;
    if (!templateWrapper) return;

    const index = templateWrappers.current.indexOf(templateWrapper);

    if (index !== -1) {
      const templateWrapperHeight =
        templateWrapper.children[0]?.clientHeight || 0;

      if (templateWrapperHeight > 0) {
        individualScrollHeights.current[index] = templateWrapperHeight;
        setHeightsVersion((prev) => Number(prev) + 1);

        // Recalculate the total scrollHeight
        const baseTemplateHeight =
          scrollContainerRef.current?.offsetHeight || 0;
        let scrollHeight = baseTemplateHeight;
        individualScrollHeights.current.forEach(
          (height) => (scrollHeight += height)
        );

        const scrollerElement = document.querySelector(
          ".scroller"
        ) as HTMLElement | null;
        if (scrollerElement) {
          scrollerElement.style.height = `${scrollHeight}px`;
        }

        handleScroll(); // Update scroll behavior after height change
      }
    }
  });

  resizeObserver.observe(templateElement);
};

export const mountTemplate = (
  wrapperElement: HTMLElement | null,
  templateElement: HTMLElement | null,
  templateWrappers: MutableRefObject<(HTMLElement | null)[]>,
  individualScrollHeights: MutableRefObject<number[]>,
  setHeightsVersion: Dispatch<SetStateAction<number>>,
  scrollContainerRef: RefObject<HTMLElement>,
  handleScroll: () => void
): void => {
  if (!wrapperElement || !templateElement) return;

  if (!templateWrappers.current.includes(wrapperElement)) {
    templateWrappers.current.push(wrapperElement);
    const wrapperHeight = wrapperElement.children[0]?.clientHeight || 0;

    if (wrapperHeight > 0) {
      individualScrollHeights.current.push(wrapperHeight);
      setHeightsVersion((prev) => Number(prev) + 1);

      const baseTemplateHeight = scrollContainerRef.current?.offsetHeight || 0;
      const totalScrollHeight = individualScrollHeights.current.reduce(
        (acc, height) => acc + height,
        baseTemplateHeight
      );

      const scrollerElement = document.querySelector(
        ".scroller"
      ) as HTMLElement | null;

      if (scrollerElement) {
        scrollerElement.style.height = `${totalScrollHeight}px`;
      }

      wrapperElement.style.opacity = "1";
      wrapperElement.style.transition = "opacity 0.3s ease-in-out";

      // Observe the template size
      observeTemplateSize(
        templateElement,
        templateWrappers,
        individualScrollHeights,
        setHeightsVersion,
        scrollContainerRef,
        handleScroll
      );
    }
  }
};
